import React from "react";

import { SanityProductReferenceCta } from "@graphql-types";
import { useCheckScreenWidth } from "@util/hooks";
import { Container, GridContainer } from "@util/standard";
import CtaBlock from "./ctaBlock";
import ProductCard from "./productCard";

interface Props {
  data: SanityProductReferenceCta;
}

function ProductReferenceCta({ data }: Props) {
  const { isMobileWidth } = useCheckScreenWidth();

  return (
    <Container margin="120px 0" width="100%" justifyContent="center" flexDirection="column">
      {data.CtaBlock && <CtaBlock data={data.CtaBlock} margin="0 0 85px 0" />}
      <GridContainer
        width="65%"
        tabletWidth="90%"
        margin="auto"
        repeat={3}
        tabletRepeat={3}
        mobileRepeat={2}
        columnGap="15px"
        mobileColumnGap="8px"
      >
        {data.productRefs?.map((product, index) => {
          if (product == null || data.productRefs == null) {
            return null;
          }

          if (isMobileWidth && index === data.productRefs.length - 1) {
            return null;
          }

          return <ProductCard data={product} key={product._id} />;
        })}
      </GridContainer>
    </Container>
  );
}

export default ProductReferenceCta;
